<template>
  <mdb-container>
    <Publicheader></Publicheader>
    <mdb-row v-if="isloading">
      <mdb-col col="12" class="pt-3">
        <div class="d-flex justify-content-center">
          <h1>Chargement en cours</h1>
        </div>
      </mdb-col>
    </mdb-row>
    <mdb-row v-if="isloading">
      <mdb-col col="12" class="pt-3">
        <div class="d-flex justify-content-center">
          <mdb-spinner big multicolor />
        </div>
      </mdb-col>
    </mdb-row>
    <mdb-row class="mt-4" v-if="errmsg">
      <mdb-col>
        <mdb-alert color="danger">
          {{errmsg}}
        </mdb-alert>
      </mdb-col>
    </mdb-row>
    <main class="mt-3" v-if="video.id">
      <mdb-row>
        <mdb-col md="12">
          <h4>{{ videotitle }}</h4>
        </mdb-col>
      </mdb-row>
      <mdb-row>
        <mdb-col :md="cinemamode? '12' : '8'" class="mb-4" v-if="videostate=='VIDEO_STATE_BROADCAST_STARTED' || videostate=='VIDEO_STATE_BROADCAST_NOT_STARTED'">
          <div class="videoWrapper" @click="autoplay">
            <youtube width="100%" :resize=false :fitParent=false :height="cinemamode? '630' : '400'" :videoId="video.id" @ready="playerready"></youtube>
            <div id="overlay" v-bind:class="{ cinemamodecls: cinemamode }"><h1 class="blue-text" style="font-weight: bold; top: 15px; position: relative; width: 100%; text-align: center; background-color: white; padding-top: 20px;padding-bottom: 20px;" v-if="videostate=='VIDEO_STATE_BROADCAST_NOT_STARTED'">La présentation va bientôt démarrer</h1></div>
          </div>
          <h5 class="blue-text" v-if="videopresentedby">Présenté par: <strong>{{videopresentedby}}</strong></h5>
          <mdb-btn v-if="!isstarted" v-on:click="playvideo()">Cliquez ici si la vidéo ne démarre pas</mdb-btn>
          <mdb-switch v-model="cinemamode" onLabel="Grand lecteur" offLabel="Petit lecteur" />
        </mdb-col>
        <mdb-col :md="cinemamode? '12' : '8'" class="mb-4" v-if="videostate!=='VIDEO_STATE_BROADCAST_STARTED' && videostate!=='VIDEO_STATE_BROADCAST_NOT_STARTED'">
          <h2>Cette présentation est maintenant terminée!</h2>
        </mdb-col>
        <mdb-col :md="cinemamode? '12' : '4'">
          <mdb-card >
            <mdb-card-header color="grey darken-3">Questions / commentaires</mdb-card-header>
            <mdb-card-body>
              <div>
                <strong>Système:</strong> Bonjour!<br />
                Tout au long de cette présentation, vous pouvez nous poser des questions ici.
                Bonne écoute!
                <mdb-alert color="success" class="mt-3" v-if="askquestionsuccess">
                  Nous avons bien reçu votre question!
                </mdb-alert>
              </div>
              <mdb-input type="textarea" label="Votre question" :rows="2" v-model="currentquestion"/>
              <mdb-btn size="sm" outline="elegant" class="float-right" v-on:click="sendquestion">Envoyer!</mdb-btn>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
      </mdb-row>
    </main>
    <Publicfooter></Publicfooter>
  </mdb-container>
</template>

<script>
    import Vue from 'vue';
    import Publicheader from '@/components/Publicheader.vue';
    import Publicfooter from '@/components/Publicfooter.vue';
    import VueYoutube from 'vue-youtube';

    import {
        mdbContainer,
        mdbRow,
        mdbCol,
        mdbBtn,
        mdbCard,
        mdbCardHeader,
        mdbCardBody,
        mdbInput,
        mdbAlert,
        mdbSwitch,
        mdbSpinner,
    } from 'mdbvue';

    import Apicall from '../libs/Apicall';

    Vue.use(VueYoutube);

    export default Vue.extend({
        name: 'Player',
        components: {
            Publicheader,
            Publicfooter,
            mdbContainer,
            mdbRow,
            mdbCol,
            mdbBtn,
            mdbCard,
            mdbCardHeader,
            mdbCardBody,
            mdbInput,
            mdbAlert,
            mdbSwitch,
            mdbSpinner,
        },
        data() {
            return {
                dummy: '',
                isloading: true,
                video: {
                    id: null,
                    playerVars: {
                        controls: 0,
                        mode: 'opaque',
                        rel: 0,
                        autohide: 1,
                        showinfo: 0,
                        wmode: 'opaque',
                        autoplay: 1,
                    },
                },
                videotitle: null,
                videopresentedby: null,
                videostate: null,
                theplayer: null,
                interval: null,
                isstarted: false,
                errmsg: null,
                currentquestion: '',
                askquestionsuccess: false,
                cinemamode: false,
                ping: null,
                refreshvideostateInterval: null,
                pingviewInterval: null,
            };
        },
        methods: {
            playerready(player) {
                this.theplayer = player;
                this.playvideo();
            },
            playvideo() {
                if (this.theplayer.getPlayerState() !== 1) {
                    this.theplayer.playVideo();
                    if (this.theplayer.isMuted()) {
                      this.theplayer.unMute();
                    }
                    this.theplayer.setVolume(100); // le son dans le tapis
                }
            },
            autoplay() {
                if (this.theplayer) {
                    if (this.theplayer.getPlayerState() !== 1) {
                        this.theplayer.playVideo();
                    } else {
                        window.clearInterval(this.interval);
                        this.theplayer.setVolume(100); // le son dans le tapis
                        this.isstarted = true;
                    }
                }
            },
            sendquestion() {
                const self = this;
                const api = new Apicall();
                api.askQuestion(this.currentquestion, this.$route.params.ident).then((response) => {
                    self.currentquestion = '';
                    self.askquestionsuccess = true;
                    window.setTimeout(() => { self.askquestionsuccess = false; }, 3000);
                });
            },
            refreshvideostate() {
                const self = this;
                const api = new Apicall();
                api.getVideoState(this.$route.params.ident).then((response) => {
                    self.videostate = response.data.videostate;
                });
            },
            pingview() {
                const self = this;
                const api = new Apicall();
                api.pingView(this.$route.params.ident).then((response) => {
                    self.ping = response.status;
                });
            },
        },
        mounted() {
            const self = this;
            const api = new Apicall();
            window.localStorage.removeItem('agentcontext');
            window.localStorage.removeItem('agencycontext');
            api.getPlayerInfos(this.$route.params.ident).then((response) => {
                self.isloading = false;
                if (response.status === 'OK') {
                    self.video.id = response.data.webinar.yid;
                    self.videotitle = response.data.webinar.title;
                    self.videopresentedby = response.data.webinar.presentedby;
                    self.videostate = response.data.webinar.ystate;

                    if (self.$store.state.agentcontext !== response.data.context.agentcontext && response.data.context.agentcontext) {
                        self.$store.state.agentcontext = response.data.context.agentcontext;
                        self.$store.state.agencycontext = '';
                    }
                    if (response.data.context.agentcontext) {
                        window.localStorage.setItem('agentcontext', response.data.context.agentcontext);
                    }
                    if (self.$store.state.agencycontext !== response.data.context.agencycontext && response.data.context.agencycontext) {
                        self.$store.state.agencycontext = response.data.context.agencycontext;
                        self.$store.state.agentcontext = '';
                    }
                    if (response.data.context.agencycontext) {
                        window.localStorage.setItem('agencycontext', response.data.context.agencycontext);
                    }

                    this.interval = window.setInterval(this.autoplay, 2000);

                    self.refreshvideostateInterval = window.setInterval(this.refreshvideostate, 1000 * 60 * 1);

                    self.pingviewInterval = window.setInterval(this.pingview, 1000 * 60 * 3);
                } else {
                    self.errmsg = response.msg;
                }
            });
        },
        beforeDestroy() {
            clearInterval(this.refreshvideostateInterval);
            clearInterval(this.pingviewInterval);
        },
    });
</script>

<style scoped>
  #overlay {
    display:block;
    position: absolute;
    margin-top: -408px;
    width: 96%;
    height: 402px;
  }
  .cinemamodecls {
    height: 632px!important;
    margin-top: -638px!important;
    width: 98%!important;
  }
</style>

<style>
  /* Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl {
      max-width: 100%;
    }
  }

  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl {
      max-width: 100%;
    }
  }

  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 100%;
    }
  }

  /* Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1140px;
    }
  }
</style>
